import React from 'react'
import { MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

class AltList extends React.Component {
  render() {
    return (
      <MDBRow
        className={`about-item align-items-center ${
          this.props.placement === 'right' ? 'flex-row-reverse' : ''
        }`}
      >
        <MDBCol lg="6">
          <GatsbyImage
            image={this.props.image}
            alt={this.props.alt}
            className="rounded innovation-box"
          />
        </MDBCol>
        <MDBCol lg="6">
          <div className="about-content">
            <h3
              className={
                this.props.titleClass != null
                  ? this.props.titleClass
                  : 'font-alt font-w-700 letter-spacing-1 title-xs-medium title-large text-very-dark-blue'
              }
            >
              {this.props.title}
            </h3>

            {this.props.bullets.map(bullet => {
              return (
                <ul className="list-unstyled font-w-400 text-medium">
                  <MDBAnimation reveal type="fadeInUp" delay="0.3s">
                    <li>{bullet.text}</li>
                  </MDBAnimation>
                </ul>
              )
            })}
          </div>
        </MDBCol>
      </MDBRow>
    )
  }
}

export default AltList