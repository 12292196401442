import React from 'react'
import Showdown from 'showdown'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faDownload, faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'gatsby'

class solutionText extends React.Component {  
  render() {
    const textClass = this.props.sectionClass.includes('bg-blue-gradient') ? 'text-white' : 'text-black'
    const linkClass = this.props.sectionClass.includes('bg-blue-gradient') ? 'divlink-we' : 'divlink' 
    const justify = this.props.sectionCenter ? 'justify-content-center' : 'justify-content-left'
    const textJustify = this.props.sectionCenter ? 'text-center' : 'text-left'
    const converter = new Showdown.Converter()

    return (
      <section className={ this.props.sectionClass ? this.props.sectionClass : `bg-white` }>
        
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
            {!this.props.data.title.includes('***') &&
              <h2 className={`font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-7 ${textClass} ${textJustify}`}>
                {this.props.data.title}
                <span id={this.props.data.anchorid} className="anchor">&nbsp;</span> 
              </h2>
              }
              {this.props.data.subtitle && 
              <div className={`text-medium ${textClass} ${textJustify}`}>{this.props.data.subtitle}</div>
              }

              {this.props.data.description &&
                <div
                  className={`text-medium richtext ${textClass} ${linkClass} ${textJustify}`}
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(this.props.data.description),
                  }}
                />
              }


              {(this.props.data.link || this.props.data.document) && (
                <>
                {this.props.data.link ? (
                  <>            
                  {this.props.data.link.substring(0, 4) != 'http' && this.props.data.link.substring(0, 8) != '/static/' && this.props.data.link.substring(0, 1) != '#' ? (
                    <Link to={this.props.data.link} className="nav-link btn btn-mdb-color btn-sm-block mt-3" >
                      <FontAwesomeIcon icon={faChevronRight} className="mr-2" />{this.props.data.linktext}
                    </Link>
                  ) : (
                    <a href={this.props.data.link} className="nav-link btn btn-mdb-color btn-sm-block mt-3" target="_blank">
                      <FontAwesomeIcon icon={faChevronDown} className="mr-2" /> {this.props.data.linktext}
                    </a>
                  )}                  
                  </>
                ) : (
                    <a href={this.props.data.document.publicURL} className="nav-link btn btn-mdb-color btn-sm-block mt-3" target="_blank">
                      <FontAwesomeIcon icon={faDownload} className="mr-2" /> {this.props.data.linktext}
                    </a>
                )}                
               </>
              )}



            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    )
  }
}

export default solutionText
